<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat height="50">
        <v-toolbar-title>Dimensions</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <!-- start of defaults list -->
        <v-row>
          <v-col cols="6">
            <v-data-table
              :headers="headers"
              :items="userDefaults"
              :loading="loading"
              loading-text="Loading... Please wait"
            >
              <template v-slot:item.DimDesc="{ item }">
                <v-btn
                  color="accent"
                  text
                  :to="`/cost-accounting/dimensions/${item.id}`"
                  >{{ item.DimDesc }}</v-btn
                >
              </template>
              <template v-slot:item.DimStatus="{ item }">
                {{ item.DimStatus || "Active" }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <!-- end of defaults table list -->
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  components: {},
  data: () => ({
    search: "",
    loading: false,
    record: {},
    userDefaults: [],
    headers: [
      { text: "Name", value: "DimName" },
      { text: "Description", value: "DimDesc" },
      { text: "Status", value: "DimStatus" },
    ],
  }),
  methods: {
    getData() {
      const self = this;
      this.$store
        .dispatch("get", `/dimensions`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.userDefaults = res.ResponseData;
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getData();
  },
};
</script>